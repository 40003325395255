import React from "react"
import { Link } from "gatsby"
import { SwiperSlide } from "swiper/react"

import { withFeaturedSlider } from "./withFeaturedSlider"
import { Wrapper, Panel, Title, ViewAll, Prev, Next, Outer } from "./FeaturedSliderStyles"
import { Slider } from "../Slider"
import { Icon } from "../../Icon/Icon"

export const FeaturedSlider = withFeaturedSlider(({ id, title, items, callToActionText, callToActionUrl, Card, layout, cardLayout }) => (
  <Wrapper>
    {title ? (
      <Panel layout={layout}>
        <Title layout={layout}>{title}</Title>
        {callToActionUrl ? (
          <ViewAll as={Link} to={callToActionUrl}>
            <span>{callToActionText}</span>
          </ViewAll>
        ) : null}
      </Panel>
    ) : null}
    <Outer>
      <Slider layout={layout} prevEl={`#${id}-prev`} nextEl={`#${id}-next`}>
        {items?.map((item, index) => (
          <SwiperSlide key={`${item.title}+${index}`}>
            <Card item={item} layout={cardLayout} />
          </SwiperSlide>
        ))}
      </Slider>
      <Prev layout={layout} id={`${id}-prev`}>
        <Icon name={"chevronLeft"} width={16} height={28} />
      </Prev>
      <Next layout={layout} id={`${id}-next`}>
        <Icon name={"chevronRight"} width={16} height={28} />
      </Next>
    </Outer>
  </Wrapper>
))
