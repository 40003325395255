import React from "react"

export const withSlider = Component => ({
                                          name = "Slider",
                                          layout,
                                          itemSpacing = null,
                                          prevEl = "",
                                          nextEl = "",
                                          ...rest
                                        }) => {
  const navigation = {
    prevEl,
    nextEl,
  }

  const config = {
    compactProducts: {
      slidesPerView: 2,
      spaceBetween: 0,
      breakpoints: {
        769: {
          slidesPerView: 4,
        },
      },
    },
    "product-carousel-3": {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        415: {
          slidesPerView: 1.4,
          spaceBetween: 30,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    },
    "product-carousel-4": {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        415: {
          slidesPerView: 1.4,
          spaceBetween: 30,
        },
        1025: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    },
    cartProduct: {
      slidesPerView: 1.3,
      spaceBetween: 0,
      breakpoints: {
        415: {
          slidesPerView: 2.2,
        },
        1025: {
          slidesPerView: 3.4,
          spaceBetween: 16,
        },
      },
    },
    article: {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        415: {
          slidesPerView: 2.2,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    },
    history: {
      slidesPerView: 1.2,
      spaceBetween: 0,
      breakpoints: {
        768: {
          spaceBetween: 28,
        },
      },
    },
    instagram: {
      slidesPerView: 2.5,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    },
    search: {
      slidesPerView: 2,
      spaceBetween: 0,
      breakpoints: {
        768: {
          slidesPerView: 6,
          spaceBetween: 15,
        },
      },
    },
    "imageContentCarousel-3": {
      slidesPerView: 1.2,
      spaceBetween: itemSpacing ? itemSpacing[0] : 16,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: itemSpacing ? itemSpacing[1] : 30,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: itemSpacing ? itemSpacing[1] : 30,
        },
      },
    },
    "imageContentCarousel-4": {
      slidesPerView: 1.2,
      spaceBetween: itemSpacing ? itemSpacing[0] : 16,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: itemSpacing ? itemSpacing[1] : 30,
        },
        1025: {
          slidesPerView: 4,
          spaceBetween: itemSpacing ? itemSpacing[1] : 30,
        },
      },
    },
    "imageContentCarousel-5": {
      slidesPerView: 1.2,
      spaceBetween: itemSpacing ? itemSpacing[0] : 16,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: itemSpacing ? itemSpacing[1] : 30,
        },
        1025: {
          slidesPerView: 5,
          spaceBetween: itemSpacing ? itemSpacing[1] : 30,
        },
      },
    },
  }

  Component.displayName = name
  return (
    <Component
      navigation={navigation}
      breakpoints={config?.[layout]?.breakpoints}
      spaceBetween={config?.[layout]?.spaceBetween}
      slidesPerView={config?.[layout]?.slidesPerView}
      {...rest}
    />
  )
}
