import React from "react"

export const withFeaturedSlider = Component => ({
  name = "FeaturedSlider",
  id,
  items,
  title,
  callToActionText = "",
  callToActionUrl = "",
  Card,
  layout,
  cardLayout,
}) => {
  Component.displayName = name
  return (
    <Component
      id={id}
      title={title}
      items={items}
      callToActionText={callToActionText}
      callToActionUrl={callToActionUrl}
      Card={Card}
      layout={layout}
      cardLayout={cardLayout}
    />
  )
}
