import tw, { styled } from "twin.macro"

import { UnderlinedButton } from "../../Styled/Button"

export const Wrapper = tw.div`
  relative
`

export const Panel = styled.div<LayoutProps>`
  ${tw`flex flex-row justify-between items-center `}
  ${({ layout }) => {
    if (layout === "compactProducts") return tw`py-2 px-3 bg-black text-white`
    return tw`mb-2`
  }}
`

export const Title = styled.h3<LayoutProps>`
  ${tw`font-bold uppercase`}
  ${({ layout }) => {
    if (layout === "compactProducts") return tw`text-18 leading-19`
    return tw`text-18 leading-19`
  }}
`

export const ViewAll = styled(UnderlinedButton)`
  ${tw`block mb-1-2 normal-case text-12`}
`

export const Prev = styled.button<LayoutProps>`
  ${tw`absolute top-1/2 z-10 translate-y-[-50%]`}
  ${({ layout }) => {
    if (layout === "compactProducts") return tw`top-1/2 left-1 md:left-3`
    if (layout === "normalProducts") return tw`top-2/5 -left-1-6 md:-left-3`
  }}
  &.swiper-button-disabled {
    ${tw`opacity-50 text-grey`}
  }
`

export const Next = styled.button<LayoutProps>`
  ${tw`absolute z-10 translate-y-[-50%]`}
  ${({ layout }) => {
    if (layout === "compactProducts") return tw`top-1/2 right-1 md:right-3`
    if (layout === "normalProducts") return tw`top-2/5 -right-1-6 md:-right-3`
  }}
  &.swiper-button-disabled {
    ${tw`opacity-50 text-grey`}
  }
`

export const Outer = styled.div`
  ${tw`relative`}
`

type LayoutProps = {
  layout?: "product" | "article" | "instagram"
}
