import React, { useEffect, useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useAnalytics } from "../../../hooks/useAnalytics"
import { useShopify } from "../../../hooks/useShopify"
import { useRoutes } from "../../../hooks/useRoutes"

export const withFeaturedProducts = Component => ({
  name = "FeaturedProducts",
  title,
  link: originalLink = {},
  layout = "compact",
  cardLayout = "normal",
  collection,
  products,
  analytics = {},
}) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const { getCollection, getHandle, getProducts, imageNormaliser } = useShopify()
  const { linkResolver } = useRoutes()

  const [items, setItems] = useState([])

  const fetchItems = async () => {
    const items = getHandle(collection)
      ? await getCollection({ firstProducts: 10, firstImages: 2, firstVariants: 1, handle: getHandle(collection) })
      : products?.length > 0
      ? await getProducts({ handles: products?.map(item => getHandle(item)), firstImages: 2, firstVariants: 1 })
      : []
    setItems(formatProducts(items?.products || items))
  }

  const formatProducts = products =>
    products.map(product => ({
      images: product?.images?.map(image => imageNormaliser(image, 500)),
      key: product?.id,
      id: product?.id,
      handle: product?.handle,
      url: `${routes?.PRODUCT}/${getHandle(product)}`,
      title: product?.title,
      tags: product?.tags,
      defaultVariant: product?.variants?.[0],
    }))

  //intentionally only run once at first render
  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const link = linkResolver(originalLink)

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...analytics })}>
      <Component title={title} link={link} items={items} layout={layout} cardLayout={cardLayout} />
    </VisibilitySensor>
  )
}
